import { Footer, Head, DynamicBlock, Header } from 'components';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Storyblok, { useStoryblok } from 'utils/storyblok';
import { StoryData } from 'storyblok-js-client/types';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { useEffect } from 'react';

type HomeProps = {
  story: StoryData;
  preview: boolean;
  navigation: {
    content: {
      header: any;
      footer: any;
    };
  };
  locale: string;
};

function HomePage({ story, locale }: HomeProps) {
  const { t } = useTranslation('common');

  const {
    content: { body, header_theme: headerTheme, header, footer },
  } = story;

  useEffect(() => {
    useStoryblok(story, true);
  }, []);

  return (
    <>
      <Head title={t('title')} metadescription="Page Description" />
      <PageWrapper locale={locale}>
        <Header data={header[0].reference.content} locale={locale} headerTheme={headerTheme} />
        {body?.map((item, i) => {
          return <DynamicBlock key={i} blok={item} />;
        })}
        <Footer data={footer[0].reference.content} />
      </PageWrapper>
    </>
  );
}

export async function getStaticProps({ locale = 'en-us', preview = false }) {
  const slug = 'home';
  const sbParams = {
    version: 'published',
    cv: Date.now(),
    resolve_relations: 'reference.reference',
  };

  if (preview) {
    sbParams.version = 'draft';
  }

  const {
    data: { stories },
  } = await Storyblok.get(`cdn/stories`, {
    ...sbParams,
    starts_with: `${locale.toLowerCase()}/${slug}`,
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      story: stories[0] ?? null,
      locale,
    },
  };
}

export default HomePage;
