import { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Storyblok from 'utils/storyblok';
import { Modal } from 'components/Modal/Modal';
import { useLocale, getClosestLocation, LOCALE_MAP } from 'utils/hooks/useLocale';
import { ELEMENT_TYPES, Text, TEXT_TYPES } from 'components/Text/Text';
import { Button } from 'components/Button/Button';
import { mq } from '../../theme';

const UserLocation = ({ locale }: { locale: string }) => {
  const { sessionStorage } = window;
  const storedPosition = sessionStorage.getItem('region');

  const [showModal, setShowModal] = useState(false);
  const { locales, changeLocale } = useLocale();

  useEffect(() => {
    const fetchUserPosition = async () =>
      await axios
        .post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.NEXT_PUBLIC_LOCALE_KEY}`)
        .then(async ({ data: { location } }) => {
          const { data } = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${process.env.NEXT_PUBLIC_LOCALE_KEY}`,
          );

          const country =
            data.results[0].address_components.find((component) => component.types.includes('country')).long_name ||
            'USA';

          if (country !== LOCALE_MAP[locale]) {
            setShowModal(true);
          }

          return { latitude: location.lat, longitude: location.lng };
        })
        .catch(() => {
          throw new Error('Error fetching the user position.');
        });

    if (!storedPosition) {
      fetchUserPosition().then(async (location) => {
        try {
          const clinics = await Storyblok.get(`cdn/stories`, {
            version: 'published',
            cv: Date.now(),
            resolve_relations: 'reference.reference',
            filter_query: {
              component: {
                in: 'clinic_city',
              },
            },
          });

          const {
            data: { stories },
          } = clinics;

          const storyContent = stories.map((story) => story.content);
          const clinicsInCountry = storyContent.filter((clinic) => clinic.country === LOCALE_MAP[locale]);
          const closestClinic = await getClosestLocation(location, clinicsInCountry);
          sessionStorage.setItem('clinics', JSON.stringify(storyContent));

          if (closestClinic) {
            sessionStorage.setItem('region', closestClinic.jane_base_slug);
          }
        } catch {
          throw new Error('Could not fetch the data.');
        }
      });
    }

    () => {
      setShowModal(false);
    };
  }, []);

  const nextLocale = locales.find((loc) => loc !== locale);
  const handleModalAction = (locale) => {
    try {
      changeLocale(locale);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div>
      {showModal && (
        <Modal onModalClose={() => handleModalAction(locale)}>
          <Text
            element={ELEMENT_TYPES.P}
            type={TEXT_TYPES.HEADING_6}
            fontSize="2.2rem"
            color="white"
            content={
              nextLocale === 'en-US'
                ? `Looks like you're browsing our Canadian site from the U.S.`
                : `Looks like you're browsing our U.S. site from Canada.`
            }
          />
          <Text
            element={ELEMENT_TYPES.P}
            type={TEXT_TYPES.PARAGRAPH_7}
            content="We operate in both Canada and the US and can optimize your experience to your region. Would you like to change regions?"
          />
          <StyledButtonWrapper>
            <Button
              text={nextLocale === 'en-US' ? `Switch to US site` : `Switch to Canadian site`}
              onClick={() => {
                handleModalAction(nextLocale);
              }}
            />
            <Button
              text={nextLocale === 'en-US' ? `Stay on Canadian site` : `Stay on US Site`}
              theme="light"
              onClick={() => {
                handleModalAction(locale);
              }}
            />
          </StyledButtonWrapper>
        </Modal>
      )}
    </div>
  );
};

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  button {
    cursor: pointer;
    margin: 2rem 0.5rem 0;
  }

  ${mq('md')} {
    flex-direction: row;

    button {
      margin: 2rem 0.5rem 0;
    }
  }
`;

export const PageWrapper = ({ locale, children }: { locale: string; children: any }) => (
  <main>
    <UserLocation locale={locale} />
    {children}
  </main>
);
