import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { mq } from '../../theme';

export const Modal = ({ children, onModalClose }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  });

  const handleTabKey = (e) => {
    const focusableModalElements = modalRef?.current?.querySelectorAll('a, button');
    const firstElement = focusableModalElements[0];
    const lastElement = focusableModalElements[focusableModalElements.length - 1];

    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  const keyListenersMap = new Map([
    [27, onModalClose],
    [9, handleTabKey],
  ]);

  return createPortal(
    <StyledModalContent role="dialog" aria-modal="true" className="modal-content" ref={modalRef}>
      {children}
    </StyledModalContent>,
    document.body,
  );
};

const StyledModalContent = styled.div`
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 3rem;
  -webkit-font-smoothing: antialiased;

  ${mq('md')} {
    max-width: 60%;
  }

  p:first-of-type {
    margin-bottom: 1rem;
  }

  button:nth-of-type(2) {
    color: white;
    background: transparent;
    border: 1px solid white;
  }
`;
